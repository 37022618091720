export default [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Projects',
    url: '/projects',
  },
  {
    label: 'Blog',
    url: '/blog',
  },
  {
    label: 'About',
    url: '/about'
  },
  {
    label: 'RSS',
    url: '/rss'
  }
];
